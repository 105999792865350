import styled from 'styled-components'

// import { Card } from '../../../components/layouts/Card'
import { Box, Card, Img, Price, ReviewStars, Subtext, TextEmphasis, Title, TotalPriceLabel } from './Style'
import { Row } from '../../../components/layouts/Row'
import { Icon } from '../../../components/elements/Icon'
import { deskIcon, gymIcon, parkingIcon, poolIcon } from '../../../app/media/icons'

import { useRef } from 'react'

import { ImageSlider } from '../../../components/base/gui/controls/ImageSlider'
import { GridRow } from '../../../components/layouts/GridRow'
import { ImageSlider1 } from '../../../components/base/gui/controls/ImageSlider1'
import { ImageSlider2 } from '../../../components/base/gui/controls/ImageSlider2'
import { ImageSlider3 } from '../../../components/base/gui/controls/ImageSlider3'

export const Listing = ({ data, onMouseEnter = () => null, navigate = () => null, arrowPosition = 'inside', urlParams=""}) => {
  const swiperElRef = useRef(null)

  let { marker, unit } = data

  let {
    address,
    image,
    imagesThumbnail,
    lat,
    lng,
    listingId,
    title,
    summary,
    bathrooms,
    bedrooms,
    price,
    parking,
    gym,
    pool,
    desk,
    total = null,
    reviews,
  } = unit

  const onListingClick = () => {
    //navigate('/listing-page/' + listingId)
    window.open('/listing/' + listingId + '/' + title.replaceAll(" ","-")+urlParams);
  }

  return (
    <Card onClick={onListingClick} onMouseEnter={onMouseEnter}>
      <div style={{ height: '25rem' }}>
        <ImageSlider2 imageUrlList={imagesThumbnail} arrowPosition={arrowPosition} />
      </div>

      <GridRow colTemplate='11fr 2fr' margin='1.1rem 0' justify='space-between'>
        <Title>{title}</Title>
        {reviews && <ReviewStars stars={reviews.avg} reviewCount={reviews.total} />}
      </GridRow>

      <Row>
        <Box>
          <TextEmphasis>{bedrooms}</TextEmphasis> bedroom{bedrooms !== 1 && 's'}
        </Box>
        <Box>
          <TextEmphasis>{bathrooms}</TextEmphasis> bathroom{bathrooms !== 1 && 's'}
        </Box>

        {/* // cleaner alternative:
        {features.map(
          (feature, index) =>
            feature.property && (
              <Box key={index}>
                <Icon size='2.1rem'>{feature.icon}</Icon> {feature.label}
              </Box>
            )
        )} */}

        {parking && (
          <Box>
            <Icon size='2rem'>{parkingIcon}</Icon> parking
          </Box>
        )}
        {gym && (
          <Box>
            <Icon size='2rem'>{gymIcon}</Icon> gym
            {/* RIGHT ICON --> <i class="fa-duotone fa-dumbbell"></i> */}
          </Box>
        )}
        {pool && (
          <Box>
            <Icon size='2rem'>{poolIcon}</Icon> pool
          </Box>
        )}
        {desk && (
          <Box>
            <Icon size='2rem'>{deskIcon}</Icon> desk
          </Box>
        )}
      </Row>
      <Row align='center'>
        {total && (
          <div>
            <TotalPriceLabel>${total.toFixed(2)} total</TotalPriceLabel>
            <Subtext>+ fees & refundable deposit</Subtext>
          </div>
        )}
        {!total && (
          <div>
            <Price>From ${price.startingFrom}/night</Price>
            <Subtext> + fees & refundable deposit</Subtext>
          </div>
        )}
      </Row>
    </Card>
  )
}
